// Navbar.js
import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/icons/logo.svg";
import settingsicon from "../../assets/icons/settingsicon.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import { HOME_ENDPOINT, CHANGE_PASSWORD_ENDPOINT } from "../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/AuthActions/LogoutAction";
import { relogin } from "../../redux/actions/AuthActions/LoginAction";
import Banner from "../Banner/Banner";

const Navbar = () => {
  const logoAltText = "ZipGrid";
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);

  // State to manage the visibility of the logout and society dropdowns
  const [logoutDropdownVisible, setLogoutDropdownVisible] = useState(false);
  const [societyDropdownVisible, setSocietyDropdownVisible] = useState(false);

  useEffect(() => {
    // Event listener to close the dropdowns when clicking outside of them
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.tagName.toLowerCase() === "button"
      ) {
        setLogoutDropdownVisible(false);
        setSocietyDropdownVisible(false);
      }
    };
    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleLogoutDropdown = () => {
    setLogoutDropdownVisible(!logoutDropdownVisible);
    setSocietyDropdownVisible(false);
  };

  const toggleSocietyDropdown = () => {
    setSocietyDropdownVisible(!societyDropdownVisible);
    setLogoutDropdownVisible(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    setLogoutDropdownVisible(false);
  };

  const handleRelogin = (society_id, user_type, house_unique_id, callAPI) => {
    const data = {
      society_id,
      user_role: user_type,
      house_unique_id,
      ipAddress,
      onSuccessCallback: () => {
        window.location.href = HOME_ENDPOINT;
      },
    };
    if (!callAPI) {
      dispatch(relogin(data));
    }
    setSocietyDropdownVisible(false);
  };

  return (
    <header className="bg-white shadow-md min-w-[1280px] mx-auto ">
      {/*  max-w-7xl*/}
      <div className="mx-auto  flex items-center justify-between p-6 lg:px-2">
        {/* Logo */}
        {/* <div className="flex flex-1 w-1/4"> */}
        <button className="-m-1.5 p-1.5">
          <span className="sr-only">{logoAltText}</span>
          <img className="h-6 w-auto" src={logo} alt={logoAltText} />
        </button>
        {/* </div>
        <div className="flex justify-between w-3/4"> */}
        <Banner />

        {/* Desktop Navigation */}
        <div className="flex items-center">
          <div className="relative inline-block text-left" ref={dropdownRef}>
            <button
              className="text-sm font-semibold leading-6 text-[#888888] hover:text-red-650"
              onClick={toggleSocietyDropdown}
            >
              {loginData?.default_community?.community_name +
                `${
                  loginData?.default_community?.blocks?.wing_flat
                    ? "- " + loginData?.default_community?.blocks?.wing_flat
                    : ""
                }`}
              <span aria-hidden="true"></span>
            </button>
            {loginData?.user_type !== "Society" &&
              // loginData?.user_type !== "Employee" &&
              loginData?.all_community_details?.length > 0 &&
              societyDropdownVisible && (
                <div className="origin-top-right absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  {loginData?.all_community_details?.map((community, index) =>
                    community?.user_type === "Employee" ? (
                      <div key={community?.community_id + index}>
                        <div className="text-sm text-[#222]">
                          <button
                            onClick={() =>
                              handleRelogin(
                                community?.community_id,
                                community?.user_type,
                                0,
                                loginData?.default_community?.community_id ===
                                  community.community_id &&
                                  loginData?.user_type === "Employee"
                              )
                            }
                            className={`block text-left px-2 py-1 text-sm text-[#222] w-full ${
                              loginData?.default_community?.community_id ===
                                community.community_id &&
                              loginData?.user_type === "Employee"
                                ? "bg-red-650 text-white"
                                : "hover:bg-red-650 hover:text-white"
                            }
                           border-b border-[#DDD] ring-1 ring-black ring-opacity-5`}
                            type="button"
                          >
                            {community.community_name}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div key={community?.community_id + index}>
                        <button
                          className="block px-2 pt-2 text-sm text-left text-[#AAA] w-full"
                          type="button"
                        >
                          {community?.community_name}
                        </button>

                        <div className="text-sm text-[#222]">
                          {community?.blocks?.map((block, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handleRelogin(
                                  community?.community_id,
                                  community?.user_type,
                                  block.houseUniqueId,
                                  loginData?.default_community?.blocks
                                    ?.houseUniqueId === block?.houseUniqueId
                                )
                              }
                              className={`block px-2 py-1 text-sm text-[#222] w-full ${
                                loginData?.default_community?.blocks
                                  ?.houseUniqueId === block?.houseUniqueId
                                  ? "bg-red-650 text-white"
                                  : "hover:bg-red-650 hover:text-white"
                              }
                           border-b border-[#DDD] ring-1 ring-black ring-opacity-5`}
                              type="button"
                            >
                              {block?.wing_flat}
                            </button>
                          ))}
                        </div>
                        {/* <div className="border-t border-gray-300 mt-2" /> */}
                      </div>
                    )
                  )}
                </div>
              )}

            {/* {loginData?.user_type === "Employee" && societyDropdownVisible && (
              <div className="origin-top-right absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                {loginData?.all_community_details?.map((community) => (
                  <div key={community?.community_id}>
                    <div className="text-sm text-[#222]">
                      <button
                        onClick={() =>
                          handleRelogin(
                            community?.community_id,
                            community?.user_type,
                            0,
                            loginData?.default_community?.community_id ===
                              community.community_id
                          )
                        }
                        className={`block px-2 py-1 text-sm text-[#222] w-full ${
                          loginData?.default_community?.community_id ===
                          community.community_id
                            ? "bg-red-650 text-white"
                            : "hover:bg-red-650 hover:text-white"
                        }
                           border-b border-[#DDD] ring-1 ring-black ring-opacity-5`}
                        type="button"
                      >
                        {community.community_name}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
          <div className="border-l h-6 mx-3 border-[#CCC]"></div>
          <div className="relative inline-block text-left">
            <button onClick={toggleLogoutDropdown}>
              <img src={settingsicon} alt="settings" className="mr-4" />
            </button>
            {/* Logout Dropdown */}
            {logoutDropdownVisible && (
              <>
                <div className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white border border-[#CCC]  ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <button
                    className=" text-start border-[#CCC] border-b  block px-4 py-2 text-sm text-[#222] w-full hover:bg-red-650 hover:text-white"
                    onClick={() => navigate(`${CHANGE_PASSWORD_ENDPOINT}`)}
                    type="button"
                  >
                    <LockOpenIcon className="mr-2" />
                    Change Password
                  </button>
                  <button
                    className=" text-start   block px-4 py-2 text-sm text-[#222] w-full hover:bg-red-650 hover:text-white"
                    onClick={handleLogout}
                    type="button"
                  >
                    <LogoutIcon className="mr-2" />
                    Logout
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </header>
  );
};

export default Navbar;
