import React from "react";
import Select from "react-select";
import "./Dropdown.css";
import { ErrorMessage } from "formik";
import zIndex from "@mui/material/styles/zIndex";

export const Dropdown2 = ({
  options,
  onSelect,
  width,
  height,
  placeholder,
  name,
  value,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: "20px",
      height: height || "22px",
      borderRadius: "0.375rem",
      fontSize: "12px",
      fontWeight: 600,
    }),
    container: (provided, state) => ({
      ...provided,
      width: width || "200px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "fit-content",
      margin: "0",
    }),
    option: (base, { isDisabled }) => ({
      ...base,
      fontSize: "12px",
      fontWeight: 600,
      color: isDisabled ? "#AAAAAA" : "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px ",
      svg: {
        width: "14.5px",
      },
    }),
    loadingIndicator: (base) => ({
      ...base,
      color: "red",
    }),
  };

  return (
    <div>
      <Select
        placeholder={placeholder}
        className="react-select"
        classNamePrefix="react-select"
        value={value}
        onChange={onSelect}
        options={options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          isFocused: "#DDD",
          colors: {
            ...theme.colors,
            primary25: "#DDD",
            primary: "#DDD",
          },
        })}
      />
    </div>
  );
};
export const DropdownFM2 = ({
  options,
  onSelect,
  width,
  height,
  placeholder,
  name,
  value,
  noOptionsMessage,
  loading,
  disabled = false,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: "20px",
      height: height || "22px",
      borderRadius: "0.375rem",
      fontSize: "12px",
      fontWeight: 600,
    }),
    container: (provided, state) => ({
      ...provided,
      width: width || "200px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "fit-content",
      margin: "0",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex : 100,
    }),
    option: (base, { data }) => {
      return {
        ...base,
        fontSize: "12px",
        fontWeight: 600,
        color: data.isDisabled ? "#AAAAAA" : data.color ? data.color : "black",
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px ",
      svg: {
        width: "14.5px",
      },
    }),
    loadingIndicator: (base) => ({
      ...base,
      color: "red",
    }),
  };

  return (
    <div className="mb-2">
      <Select
        placeholder={placeholder}
        className="w-full react-select"
        classNamePrefix="react-select"
        value={value}
        onChange={onSelect}
        options={options}
        styles={customStyles}
        isLoading={loading}
        theme={(theme) => ({
          ...theme,
          isFocused: "#DDD",
          colors: {
            ...theme.colors,
            primary25: "#CCC",
            primary: "#CCC",
          },
        })}
        name={name}
        isDisabled={disabled}
        noOptionsMessage={() => noOptionsMessage}
      />
      <div className={`text-red-500 text-xs italic h-auto w-[${width}]`}>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
export default Dropdown2;